<template>
    <section>
        <div class="container" style="background-color: #02004D;">
            <div style="height: 2%;"></div>
            <el-row style="height: 10%;">
                <img :src="dataScreenHead" alt="" style="height: 100%;width: 100%;">
                <div style="height: 70%;position: relative;top: -100%;" class="flex justify-center align-center">
                    <div style="font-size: 30px;font-weight: 400;color: #FFFFFF;width: 340px;text-align-last: justify;">富航美寓智慧租房驾驶舱</div>
                </div>
                <div style="height: 150%;position: relative;top: -200%;" class="flex justify-end align-center">
                    <div style="width: 100px;">
                        <img :src="fullscreen ? ck : qp" @click="handleFullScreen"  alt=""/>
                    </div>
                </div>
            </el-row>
            <el-row style="height: 88%;">
                <el-col :span="6" style="height: 100%;padding: 0 20px">
                    <div style="height: 55%;">
                        <project-proportion ref="projectProportion" />
                    </div>
                    <div style="height: 45%;">
                        <region-proportion ref="regionProportion" />
                    </div>
                </el-col>
                <el-col :span="12" style="height: 100%;">
                    <!-- <div style="height: 15%;">
                        <total-header ref="totalHeader" />
                    </div> -->
                    <div style="height: 63%;">
                        <center-map class="gao-de-map" ref="gaoDeMapRef" :markers="houseList" :marker-content="createMarkerContent" />
                    </div>
                    <div style="height: 40%;">
                        <wxapp-visits ref="wxappVisits" />
                    </div>
                </el-col>
                <el-col :span="6" style="height: 100%;padding:0 20px">
                    <div style="height: 33.3%;">
                        <tenancy-proportion ref="tenancyProportion" />
                    </div>
                    <div style="height: 33.3%;">
                        <fund-situation ref="fundSituation"/>
                    </div>
                    <div style="height: 33.3%;">
                        <hire-situation ref="hireSituation"/>
                    </div>
                </el-col>
            </el-row>
        </div>
    </section>
</template>

<script>
const dataScreenHead = require("@images/data-screen/data-screen-head.png");
const qp = require("@images/data-screen/qp.png");
const ck = require("@images/data-screen/ck.png");
import { getBaseInfoCommunityApi } from "@/api/house-distribution";
export default {
    name: "index",
    data() {
        return {
            dataScreenHead,
            qp,
            ck,
            houseList: [],
            fullscreen: false,
        };
    },
    components: {
        projectProportion: () => import("./components/part/project-proportion"),
        regionProportion: () => import("./components/part/region-proportion"),
        tenancyProportion: () => import("./components/part/tenancy-proportion"),
        fundSituation: () => import("./components/part/fund-situation"),
        hireSituation: () => import("./components/part/hire-situation"),
        // totalHeader: () => import("./components/part/total-header"),
        centerMap: () => import("./components/part/center-map"),
        wxappVisits: () => import('./components/part/wxapp-visits')
    },
    methods: {
        createMarkerContent(data) {
            return `<section class="marker-content">
                         <div class="header">${ data.name } ${data.apartmentsCount || 0}套</div>
                         <div class="content flex align-center">
                              <div class="item flex align-center">
                                   <div class="label">已租：</div>
                                   <div class="value">${ data.rentingCount || 0 }套</div>
                              </div>
                              <div class="item flex align-center">
                                   <div class="label">空置：</div>
                                   <div class="value">${ data.vacantCount || 0 }套</div>
                              </div>
                              <div class="item flex align-center">
                                   <div class="label">预约：</div>
                                   <div class="value">${ data.boundCount || 0 }套</div>
                              </div>
                         </div>
                    </section>`;
        },
        async getBaseInfoCommunity() {
            try {
                // 获取小区列表
                const { list = [] } = await getBaseInfoCommunityApi();
                this.houseList = list;
            } catch (e) {
                console.error("获取小区列表", e);
            }
        },
        // 全屏事件
        handleFullScreen(){
            let element = document.documentElement;
            // 判断是否已经是全屏
            // 如果是全屏，退出
            if (this.fullscreen) {
                if (document.exitFullscreen) {
                    document.exitFullscreen();
                } else if (document.webkitCancelFullScreen) {
                    document.webkitCancelFullScreen();
                } else if (document.mozCancelFullScreen) {
                    document.mozCancelFullScreen();
                } else if (document.msExitFullscreen) {
                    document.msExitFullscreen();
                }
                console.log('已还原！');
            } else {    // 否则，进入全屏
                if (element.requestFullscreen) {
                    element.requestFullscreen();
                } else if (element.webkitRequestFullScreen) {
                    element.webkitRequestFullScreen();
                } else if (element.mozRequestFullScreen) {
                    element.mozRequestFullScreen();
                } else if (element.msRequestFullscreen) {
                    // IE11
                    element.msRequestFullscreen();
                }
                console.log('已全屏！');
            }
            // 改变当前全屏状态
            this.fullscreen = !this.fullscreen;
        }
    },
    mounted() {
        this.getBaseInfoCommunity();
    }
};
</script>

<style lang="scss" scoped>
.container {
    height: 100vh;
}
</style>